<template>
   <div class="breadcrumb_section">
      <ul>
         <li v-for="(link, index) in links" :key="index" :class="{active: link.active}">
            <template v-if="link.active">{{ title ? title : link.title }}</template>
            <router-link v-else :to="link.path">{{ link.title }}</router-link>
         </li>
      </ul>
      <!-- <router-link :to="{path: backButtonPath}" v-if="showBackButton && backButtonPath" class="zuruck_button">
         {{ __t('back') }}
      </router-link> -->
      <!-- <a href="#" @click.prevent="$router.go(-1)" v-if="showBackButton && backButtonPath == null" class="zuruck_button">
         {{ __t('back') }}
      </a> -->
   </div>
</template>

<script>
   export default {
      name: "Breadcrumb",
      props: {
         title: {
            type: String,
            required: false,
            default: null
         },
         links: {
            type: Array,
            required: true
         },
         showBackButton: {
            type: Boolean,
            required: false,
            default: true
         },
         backButtonPath: {
            type: String,
            required: false,
            default: null
         }
      }
   }
</script>
