<template>
   <div class="page_section" v-if="blog.bySlug.id">
      <MobileTitle :title="blog.bySlug.title"/>
      <Banner :content="banner"/>
      <div class="container">
         <Breadcrumb :links="breadcrumb" class="mb30"/>
      </div>
      <ContentElements v-for="(element, index) in blog.bySlug.content_elements" :content="element" :key="index"/>
      <Footer/>

      <a class="backend-edit" :href="backendUrl" v-if="backendAdminLoggedIn">Edit page</a>
   </div>
</template>

<script>
   import {mapState} from 'vuex';
   import {config} from "../../config";
   import Footer from "../_common/Footer";
   import MobileTitle from "../_common/MobileTitle";
   import Banner from "../_content_elements/Banner";
   import Breadcrumb from "@/components/_common/Breadcrumb";
   import ContentElements from "../_content_elements/ContentElements";

   export default {
      name: "BlogArticle",
      components: {
         Breadcrumb,
         Banner,
         Footer,
         MobileTitle,
         ContentElements
      },
      data() {
         return {
            pageLoaded: false,
            abellioSettings: {
               adminLoggedIn: true,
               backend_url: '--'
            }
         }
      },
      computed: {
         ...mapState([
            'blog',
            'page',
            'region',
            'settings',
            'authentication'
         ]),
         breadcrumb() {
            return [
               {
                  title: this.__t('homepage'),
                  path: '/',
                  active: false
               },
               {
                  title: this.page.byID.nav_title.title,
                  path: '/' + this.page.byID.slug,
                  active: false
               },
               {
                  title: this.blog.bySlug.title,
                  path: this.$route.path,
                  active: true
               }
            ];
         },
         banner: function () {
            return {
               image: this.blog.bySlug.image ? this.blog.bySlug.image : this.settings.all.blog_default_image,
               title: this.blog.bySlug.title
            };
         },
         backendAdminLoggedIn() {
            return this.pageLoaded && this.authentication.loggedIn && this.authentication.user.user.view_admin_link;
         },
         backendUrl() {
            return this.authentication.user.user.edit_links.blog_article + '/' + this.blog.bySlug.id;
         }
      },
      created() {
         if (this.$route.params.slug) {
            this.$store.dispatch('blog/getBySlug', this.$route.params.slug).then(() => {
               this.pageLoaded = true;
            });
            this.$store.dispatch('page/getByID', config.serviceAndContactIds[this.region.currentRegion.id])
         }
      },
      beforeDestroy() {
         this.$store.commit('page/setByID', {})
         this.$store.commit('page/setBySlug', {})
      }
   }
</script>

<style lang="scss" scoped>
   .backend-edit-button {
      position: fixed;
      background: $abellio-red;
      bottom: 20px;
      right: 20px;
      padding: 10px 25px;
      z-index: 100;
      color: #FFF;
      font-weight: bold;
      -webkit-box-shadow: 0 0 40px -14px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0 0 40px -14px rgba(0, 0, 0, 0.75);
      box-shadow: 0 0 40px -14px rgba(0, 0, 0, 0.75);
   }
</style>
